import axios from 'axios';
import logzioService from './logzio-service';

const trustaffApiUrl = process.env.GATSBY_API_URL;

const apiService = {
  get: get,
  post: post,
  put: put,
  deleteRequest: deleteRequest,
};

function ignoreLoggingError(error, ignoreLoggingOnAuthFailure) {
  return (
    error.response &&
    error.response.status === 401 &&
    ignoreLoggingOnAuthFailure
  );
}

function get(
  path,
  params = {},
  headers = {},
  baseUrl,
  responseType = 'json',
  ignoreLoggingOnAuthFailure = false
) {
  const apiRoot = getApiRoot(baseUrl);

  const req = {
    method: 'get',
    url: `${apiRoot}${path}`,
    params: params,
    headers: headers,
    responseType: responseType,
  };

  return new Promise((resolve, reject) => {
    axios
      .request(req)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        if (!ignoreLoggingError(err, ignoreLoggingOnAuthFailure))
          logzioService.log(err.toJSON());
        reject(err);
      });
  });
}

function post(
  path,
  data,
  headers = {},
  baseUrl,
  responseType = 'json',
  ignoreLoggingOnAuthFailure = false
) {
  const apiRoot = getApiRoot(baseUrl);
  const req = {
    method: 'post',
    url: `${apiRoot}${path}`,
    data: data,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    responseType: responseType
  };

  return new Promise((resolve, reject) => {
    axios
      .request(req)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        if (!ignoreLoggingError(err, ignoreLoggingOnAuthFailure))
          logzioService.log(err.toJSON());

        reject(err);
      });
  });
}

function put(
  path,
  data,
  headers = {},
  baseUrl,
  ignoreLoggingOnAuthFailure = false
) {
  const apiRoot = getApiRoot(baseUrl);

  const req = {
    method: 'put',
    url: `${apiRoot}${path}`,
    data: data,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .request(req)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        if (!ignoreLoggingError(err, ignoreLoggingOnAuthFailure))
          logzioService.log(err.toJSON());

        reject(err);
      });
  });
}

function deleteRequest(
  path,
  params = {},
  headers = {},
  baseUrl,
  ignoreLoggingOnAuthFailure = false
) {
  const apiRoot = getApiRoot(baseUrl);

  const req = {
    method: 'delete',
    url: `${apiRoot}${path}`,
    params: params,
    headers: headers,
  };

  return new Promise((resolve, reject) => {
    axios
      .request(req)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        if (!ignoreLoggingError(err, ignoreLoggingOnAuthFailure))
          logzioService.log(err.toJSON());
        reject(err);
      });
  });
}

function getApiRoot(baseUrl) {
  return baseUrl ? baseUrl : trustaffApiUrl;
}

export default apiService;
