import React from 'react';
import Helmet from 'react-helmet';

import AppProvider from './src/providers/app-provider';
import AuthProvider from './src/providers/auth-provider';
import { ErrorBoundary } from './src/hocs/withErrorBoundary';

const HEAP_APP_ID = 3862882236;

export const wrapRootElement = ({ element }) => {
  const GOOGLE_TAG_MANAGER_ID = 'GTM-T3CQJ7';
  return (
    <>
      {process.env.NODE_ENV === 'production' && (
        <>
          <Helmet>
            <script>
              {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');
              `}
            </script>
            <script>
              {`
              window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid = e, window.heap.config = t = t || {};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
              heap.load(${HEAP_APP_ID});
            `}
            </script>
            <script>
              {`
              (function(w,d,t,r,u)  {    var f,n,i;    w[u]=w[u]||[],f=function()    {      var o={ti:"5665917"};      o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")    },    n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()    {      var s=this.readyState;      s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)    },    i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)  })  (window,document,"script","//bat.bing.com/bat.js","uetq");
            `}
            </script>
          </Helmet>
          <noscript>
            <iframe
              title="Google Tag Manager"
              src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
          </noscript>
        </>
      )}
      <AppProvider>
        <AuthProvider>{element}</AuthProvider>
      </AppProvider>
    </>
  );
};

export const wrapPageElement = ({ element, props }) => {
  return <ErrorBoundary>{element}</ErrorBoundary>;
};
