import { candidateFilterTypeIds } from '../constants/recruiter-candidate-filter-ids';

export const getUrlFriendlyString = str => {
  let updatedStr = str
    .trim()
    .replace(/[^\w\s]/g, '') // remove punctuation and non-alpha characters
    .toLowerCase()
    .replace(/\s/g, '-'); // replace spaces with dashes
  return updatedStr;
};

export const getJobPath = ({
  id,
  city,
  state,
  professionDescription,
  specialtyName,
}) => {
  let path = '/jobs/';
  if (city) {
    path += `${getUrlFriendlyString(city)}-`;
  }
  if (state && state.abbreviation) {
    path += `${getUrlFriendlyString(state.abbreviation)}/`;
  }
  if (professionDescription) {
    path += `${getUrlFriendlyString(professionDescription)}-`;
  }
  if (specialtyName) {
    path += `${getUrlFriendlyString(specialtyName)}-`;
  }
  path += `${id}`;
  return path;
};

export const isLocalUrl = url => {
  return /localhost/.test(url);
};

export function getProfileApiUrl(profileId, sectionSlug) {
  return `/api/profiles/${profileId}/${sectionSlug}`;
}

export function getSuggestionsApiUrl(profileId, sectionSlug) {
  return `/api/profile-suggestions/${profileId}/${sectionSlug}`;
}

export function getSpecialtiesApiUrl(specialtyId) {
  return `/api/specialties/${specialtyId}`;
}

export function getTokenFromUrlParam() {
  const url = new URL(document.location);

  // This regex is necessary to handle extracting one-time tokens
  // since they can contain invalid URL characters including "+"
  // which cannot be handled by url.searchParams.get()
  //
  // 1. Match any string the starts with "?t=" or "&t=" (first or second param)
  // 2. Strip out "?t=" or "&t=" and other URL params leaving only token value

  const token = url.search
    .match(/(\?|&)t=.*/g)[0]
    .replace(/(^(\?|&)t=)|&.*/g, '');
  const decodedToken = decodeURIComponent(token);
  return decodedToken;
}

export function getParamFromUrl(name) {
  const url = new URL(document.location);
  const params = url.searchParams;
  const paramValue = params.get(name);
  return paramValue;
}

export function buildCandidateSearchEndpoint(
  baseEndpoint,
  recruiterProfileId,
  filterTypeId,
  limit,
  offset,
  searchQuery
) {
  let endpoint = `${baseEndpoint}?recruiterProfileId=${recruiterProfileId}`;
  if (!isNaN(filterTypeId)) endpoint += `&filterTypeId=${filterTypeId}`;
  if (filterTypeId === candidateFilterTypeIds.TEXT_SEARCH && searchQuery !== '')
    endpoint += `&searchQuery=${searchQuery}`;
  if (!isNaN(limit)) endpoint += `&limit=${limit}`;
  if (!isNaN(offset)) endpoint += `&offset=${offset}`;
  return endpoint;
}
