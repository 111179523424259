import { isLocalUrl } from '../utils/url-helpers';
import axios from 'axios';

const logzioService = {
  log: log,
};

const middlewareApiRoot = process.env.GATSBY_API_URL;

function log(logMessage = {}) {
  if (isLocalUrl(document.location.href)) {
    return; // don't send local errors to logs
  }

  let logObj = {
    pageUrl: document.location.href,
    ...logMessage,
  };

  sendPost(logObj);
}

function sendPost(data) {
  const req = {
    method: 'post',
    url: `${middlewareApiRoot}/api/FrontEndLogs/writelog`,
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .request(req)
      .then(res => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
}

export default logzioService;
