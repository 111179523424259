const ighPortalUrl = process.env.GATSBY_IGH_PORTAL_URL;
const talentStackUrl = process.env.GATSBY_TALENT_STACK_URL;

export const externalLinks = {
  INGENOVIS_PORTAL_HOME: ighPortalUrl,
  INGENOVIS_PORTAL_REQUIREMENTS_CREDENTIALS_PAGE: `${ighPortalUrl}/account/requirements-credentials`,
  INGENOVIS_PORTAL_INTERNAL_SERVER_ERROR: `${ighPortalUrl}/500`,
  TALENT_STACK_HOME: talentStackUrl,
  TALENT_STACK_INTERNAL_SERVER_ERROR: `${talentStackUrl}/500`,
};
