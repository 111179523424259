import apiService from './api-service';

export function useTalentStackAuthApi() {
  const TALENT_STACK_AUTH_API_BASE_PATH = '/api/talent-stack-auth';

  return {
    logInTalentStackUser: function (payload) {
      const path = `${TALENT_STACK_AUTH_API_BASE_PATH}/log-in-talent-stack-user`;
      return apiService.post(path, payload);
    },
  };
}
