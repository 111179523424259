import React, { useContext, useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { navigate } from 'gatsby';
import { authRoles } from '../constants/auth-roles';
import { globalHistory } from '@reach/router';
import { useAppContext } from './app-provider';
import tokenPropertyNames from '../constants/token-property-names';
import { getParamFromUrl, getTokenFromUrlParam } from '../utils/url-helpers';
import { useMetaApi } from '../services/meta-service';
import { externalLinks } from '../constants/external-links';
import { useTalentStackAuthApi } from '../services/talent-stack-auth-service';
import { internalLinks } from '../constants/internal-links';

const AuthContext = React.createContext();

const AuthProvider = props => {
  const { setProfessionId, setIsFromTalentStack, isFromTalentStack } =
    useAppContext();
  const { logInTalentStackUser } = useTalentStackAuthApi();
  const { getMeta } = useMetaApi();
  const JWT_LOCAL_STORAGE_KEY = 'JWT';
  const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'RefreshToken';
  const AUTH0_JWT_SCOPE_FOR_WEBSITE = 'https://www.trustaff.com/app_metadata';
  const IS_FROM_TALENT_STACK_KEY = 'TalentStack';

  const [loggedIn, setLoggedIn] = useState(null);
  const [jwt, setJwt] = useState(null);
  const [tokensHaveBeenSet, setTokensHaveBeenSet] = useState(false);
  const [hasRecentlyLoggedOut, setHasRecentlyLoggedOut] = useState(false);
  const [logoutRedirectPath, setLogoutRedirectPath] = useState(null);
  const [meta, setMeta] = useState({});

  const pathname = globalHistory.location.pathname;

  const isAuth0Token = token =>
    token && token.hasOwnProperty(AUTH0_JWT_SCOPE_FOR_WEBSITE);

  const isAttemptingLoginFromTalentStack = () => {
    return !loggedIn && hasTalentStackLoginTokenInUrl();
  };

  const hasTalentStackLoginTokenInUrl = () => {
    if (!isTalentStackSubmittalLoginPath()) return false;

    const loginToken = getParamFromUrl('t');
    return !!loginToken;
  };

  const isTalentStackSubmittalLoginPath = () =>
    pathname.startsWith('/recruiter/dashboard/v2/candidate-submittal-details/');

  const getParsedRefreshTokenFromLocalStorage = () => {
    const localStorageValue = localStorage.getItem(
      REFRESH_TOKEN_LOCAL_STORAGE_KEY
    );
    return localStorageValue && JSON.parse(localStorageValue);
  };
  const [refreshToken, setRefreshToken] = useState(null);

  const tokenIsExpired = expDateISOString => {
    const currentDate = new Date();
    const expirationDate = new Date(expDateISOString);
    return currentDate.getTime() > expirationDate.getTime();
  };

  function logIn(jwt, refToken) {
    if (hasRecentlyLoggedOut) setHasRecentlyLoggedOut(false);
    setJwt(jwt);
    setRefreshToken(refToken);
  }
  const logOut = () => {
    setHasRecentlyLoggedOut(true);
    setJwt(null);
    setRefreshToken(null);
    if (!!window.heap) {
      window.heap.resetIdentity();
    }
  };

  const getDecodedJwt = () => {
    if (jwt === null) {
      return {};
    }

    return jwtDecode(jwt);
  };

  const getValueFromToken = (propName, decodedToken = null) => {
    decodedToken = decodedToken ?? getDecodedJwt();
    if (Object.keys(decodedToken).length === 0) return null;

    const isFromAuth0 = isAuth0Token(decodedToken);
    return isFromAuth0
      ? decodedToken[AUTH0_JWT_SCOPE_FOR_WEBSITE][propName]
      : decodedToken[propName];
  };

  const getProfileId = () => {
    const profileIdValue = getValueFromToken(tokenPropertyNames.PROFILE_ID);
    return parseInt(profileIdValue) || null;
  };

  const getRoles = () => {
    const roles = getValueFromToken(tokenPropertyNames.USER_ROLE);
    return Array.isArray(roles) ? roles : [roles];
  };
  const getFullName = () => {
    return {
      firstName: getValueFromToken(tokenPropertyNames.FIRST_NAME),
      lastName: getValueFromToken(tokenPropertyNames.LAST_NAME),
    };
  };

  const getProfessionIdFromToken = () => {
    const tokenProfessionId = getValueFromToken(
      tokenPropertyNames.PROFESSION_ID
    );
    return parseInt(tokenProfessionId) || null;
  };

  useEffect(() => {
    const loadConfigMetaFromApi = async () => {
      const metaFromApi = await getMeta();
      setMeta(metaFromApi.data);
    };
    loadConfigMetaFromApi();
    setRefreshToken(getParsedRefreshTokenFromLocalStorage());
    setJwt(localStorage.getItem(JWT_LOCAL_STORAGE_KEY));
    setIsFromTalentStack(localStorage.getItem(IS_FROM_TALENT_STACK_KEY));
    setTokensHaveBeenSet(true);
  }, []);

  useEffect(() => {
    // if JWT changes in state, update local storage to reflect
    if (!tokensHaveBeenSet) {
      return;
    }
    if (jwt !== null) {
      localStorage.setItem(JWT_LOCAL_STORAGE_KEY, jwt);
      if (
        hasRole(authRoles.IGH_PORTAL_CANDIDATE) ||
        (hasRole(authRoles.APPLICANT) && meta.jobApplicantRestricted)
      )
        setLogoutRedirectPath(
          externalLinks.INGENOVIS_PORTAL_REQUIREMENTS_CREDENTIALS_PAGE
        );
    } else {
      localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
    }
  }, [jwt, tokensHaveBeenSet]);

  useEffect(() => {
    // if Refresh token changes in state, update local storage and logged in state to reflect
    if (!tokensHaveBeenSet) {
      return;
    }

    if (refreshToken === null) {
      localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
      setLoggedIn(false);
    } else if (tokenIsExpired(refreshToken.exp)) {
      logOut();
    } else {
      localStorage.setItem(
        REFRESH_TOKEN_LOCAL_STORAGE_KEY,
        JSON.stringify(refreshToken)
      );
      setLoggedIn(true);
      const profileId = getProfileId();
      if (!!window.heap && profileId) {
        window.heap.identify(profileId);
      }
    }
  }, [refreshToken, tokensHaveBeenSet]);

  useEffect(() => {
    setProfessionId(getProfessionIdFromToken());
    //prevent admins and recruiters from accessing other pages meant for applicants etc. and muddying data
    if (jwt === null) return;
    if (hasRole(authRoles.ADMIN) && !pathname.startsWith('/admin'))
      navigate('/admin/recruiter-management/');
    else if (
      hasRecruiterOrAdminAssistantRole() &&
      !pathname.startsWith('/recruiter')
    ) {
      navigate('/recruiter/dashboard/');
    } else if (hasTalentStackLoginTokenInUrl()) {
      handleTalentStackUserSetup();
    } else if (talentStackUserIsAttemptingToViewRestrictedRoute(pathname)) {
      navigate(externalLinks.TALENT_STACK_HOME);
    }
  }, [jwt]);

  const hasRole = neededRole => {
    if (!jwt) return false;
    const roles = getRoles();
    return roles.includes(neededRole);
  };

  const hasRecruiterOrAdminAssistantRole = () => {
    return hasRole(authRoles.RECRUITER) || hasRole(authRoles.ADMIN_ASSISTANT);
  };

  const handleTalentStackUserSetup = () => {
    localStorage.setItem(IS_FROM_TALENT_STACK_KEY, 'true');
    setIsFromTalentStack(true);

    //This removes the login token from the url once logged in to
    //prevent subsequent attempts to login again with same token on page refresh
    navigate(pathname);
  };

  async function attemptToLogInTalentStackUser() {
    const token = getTokenFromUrlParam();
    if (!token) return;
    const response = await logInTalentStackUser(token).catch(() => {
      navigate(externalLinks.TALENT_STACK_INTERNAL_SERVER_ERROR);
    });
    const { access, refresh } = response.data;
    logIn(access, refresh);
  }

  const talentStackUserIsAttemptingToViewRestrictedRoute = path => {
    return (
      isFromTalentStack &&
      !path.startsWith(
        '/recruiter/dashboard/v2/candidate-submittal-details/'
      ) &&
      !path.startsWith('/recruiter/dashboard/v2/create-submittals/')
    );
  };

  const talentStackActiveAndAttemptingToViewLegacyLoginRoute = path => {
    return meta.isTalentStackActive && path.startsWith(internalLinks.LOGIN_V1);
  };

  const userShouldBeUsingTalentStack = () => {
    return (
      loggedIn &&
      meta.isTalentStackActive &&
      !isFromTalentStack &&
      hasRecruiterOrAdminAssistantRole()
    );
  };

  const getLoginPathBasedOnTalentStackActiveFlag = () => {
    return meta.isTalentStackActive
      ? internalLinks.LOGIN_V2
      : internalLinks.LOGIN_V1;
  };

  const hasMetaLoaded = Object.keys(meta).length > 0;

  return (
    <AuthContext.Provider
      value={{
        logIn,
        logOut,
        loggedIn,
        jwt,
        hasRole,
        setJwt,
        getDecodedJwt,
        getRoles,
        getProfileId,
        getFullName,
        refreshToken,
        setRefreshToken,
        hasRecentlyLoggedOut,
        getValueFromToken,
        logoutRedirectPath,
        meta,
        hasMetaLoaded,
        isAttemptingLoginFromTalentStack,
        attemptToLogInTalentStackUser,
        talentStackUserIsAttemptingToViewRestrictedRoute,
        talentStackActiveAndAttemptingToViewLegacyLoginRoute,
        userShouldBeUsingTalentStack,
        getLoginPathBasedOnTalentStackActiveFlag,
      }}
      {...props}
    />
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
