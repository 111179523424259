import React from 'react';
import { Link } from 'gatsby';

export default () => {
  return (
    <div
      className={`has-text-centered container`}
      style={{ marginTop: '100px' }}
    >
      <h1 className={`title is-3 is-spaced has-text-info`}>
        Oops! Something went wrong.
      </h1>
      <p className={`content`}>
        There was a problem displaying your content. You can go back to the home
        page or start a new job search.
      </p>
      <div className="content">
        <Link className={`button is-info`} to="/" id="">
          Home
        </Link>
        &nbsp;&nbsp;
        <Link className="button is-primary" to="/jobs/" id="jobs-cta">
          Search jobs
        </Link>
      </div>
      <p className={`content`}>
        Having trouble?{' '}
        <a href="/contact-us" className={`content`}>
          Report a problem
        </a>
      </p>
    </div>
  );
};
