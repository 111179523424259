import apiService from './api-service';

export function useMetaApi() {
  return {
    getMeta: function () {
      return apiService.get('/api/meta');
    },
    isDashboardVersionTwo: function () {
      return window.location.pathname.startsWith('/recruiter/dashboard/v2');
    },
  };
}
