import React, { useState, useContext } from 'react';
import { testInputForCapsLock } from '../utils/event-utils';

export const AppContext = React.createContext();

const AppProvider = props => {
  const [utmParams, setUtmParams] = useState('');
  const [applicantInfo, setApplicantInfo] = useState({});
  const [professionId, setProfessionId] = useState(null);
  const [isCapsLockActive, setIsCapsLockActive] = useState(false);
  const [isFromTalentStack, setIsFromTalentStack] = useState(false);

  const checkForCapsLock = event => {
    const isCapLockActiveCurrently = testInputForCapsLock(event);
    setIsCapsLockActive(isCapLockActiveCurrently);
  };

  return (
    <AppContext.Provider
      value={{
        setProfessionId,
        professionId,
        setUtmParams,
        utmParams,
        applicantInfo,
        setApplicantInfo,
        isCapsLockActive,
        checkForCapsLock,
        isFromTalentStack,
        setIsFromTalentStack,
      }}
      {...props}
    />
  );
};

export const useAppContext = () => useContext(AppContext);

export default AppProvider;
