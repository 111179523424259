export const authRoles = {
  ADMIN: 'Admin',
  ADMIN_ASSISTANT: 'Administrative Assistant',
  APPLICANT: 'Job Applicant',
  RECRUITER: 'Recruiter',
  IGH_PORTAL_CANDIDATE: 'Ingenovis Portal Candidate'
};

export const authRoleIds = {
  ADMIN: 1,
  ADMIN_ASSISTANT: 3,
  APPLICANT: 4,
  RECRUITER: 5,
  IGH_PORTAL_CANDIDATE: 6,
};
