module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZM9N5D2RMY"],"gtagConfig":{"optimize_id":"OPT-N4886K7"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2732181117034943"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
