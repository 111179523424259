import React from 'react';
import logzioService from '../services/logzio-service';
import ErrorFallback from '../components/error-messages/error-fallback';

/*
 * withErrorBoundary
 * Higher-order component that wraps a component in an error boundary.
 * Logs the error to logging service and provides fallback state.
 * More info on error boundaries: https://reactjs.org/docs/error-boundaries.html
 *
 */

function withErrorBoundary(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        hasError: false,
      };
    }

    componentDidCatch(error, errorInfo = {}) {
      logzioService.log({
        errorMessage: error.message,
        errorStackTrace: error.stack,
        componentStack: errorInfo.componentStack || '',
      });
    }

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    render() {
      if (this.state.hasError) {
        return <ErrorFallback />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withErrorBoundary;

const PassThroughComponent = ({ children }) => <>{children}</>;
// export a component that is wrapped in an error boundary that passes children through as a convenience
// this is for usage in gatsby-browser.js and gatsby-ssr.js
export const ErrorBoundary = withErrorBoundary(PassThroughComponent);
